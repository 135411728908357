import { Route, Switch } from 'react-router-dom';
import React from 'react';

import {
  Forbidden,
  NotFound,
  SessionExpired,
  Unknown,
  url,
} from 'lcm-iot-commons';

import AllObjects from 'lcm-iot-commons/client/lib//components/AllObjects/AllObjects';
import AssetCreate from 'lcm-iot-commons/client/lib//components/Assets/AssetCreate';
import AssetEdit from 'lcm-iot-commons/client/lib/components/Assets/AssetEdit';
import AssetDetails from 'lcm-iot-commons/client/lib/components/Assets/AssetDetails';
import Feedback from 'lcm-iot-commons/client/lib/components/Feedback/Feedback';
import InstrumentationDetails from 'lcm-iot-commons/client/lib/components/Instrumentations/InstrumentationDetails';
import InstrumentationAssign from 'lcm-iot-commons/client/lib/components/Instrumentations/InstrumentationAssign';
import InstrumentationCreate from 'lcm-iot-commons/client/lib//components/Instrumentations/InstrumentationCreate';
import InstrumentationEdit from 'lcm-iot-commons/client/lib//components/Instrumentations/InstrumentationEdit';
import NodeCreate from 'lcm-iot-commons/client/lib/components/Nodes/NodeCreate';
import NodeEdit from 'lcm-iot-commons/client/lib/components/Nodes/NodeEdit';
import AutoRouting from 'lcm-iot-commons/client/lib/components/AutoRouting';
import PlanVariantSelection from 'lcm-iot-commons/client/lib/components/PlanVariants/PlanVariantSelection';
import RedirectToApp from 'lcm-iot-commons/client/lib/components/Navigation/RedirectToApp';
import SubscriptionEdit from 'lcm-iot-commons/client/lib/components/Subscriptions/SubscriptionEdit';
import SubscriptionFailed from 'lcm-iot-commons/client/lib/components/Subscriptions/SubscriptionFailed';
import UserGroupCreate from 'lcm-iot-commons/client/lib/components/UserGroups/UserGroupCreate';
import UserGroupDetails from 'lcm-iot-commons/client/lib/components/UserGroups/UserGroupDetails';
import UserGroupEdit from 'lcm-iot-commons/client/lib/components/UserGroups/UserGroupEdit';

import TicketCreate from 'lcm-iot-commons/client/lib/components/Support/TicketCreate';
import TicketDetails from 'lcm-iot-commons/client/lib/components/Support/TicketDetails';
import Tickets from 'lcm-iot-commons/client/lib/components/Support/Tickets';
import SubscriptionCheckoutOverview from 'lcm-iot-commons/client/lib/components/Subscriptions/SubscriptionCheckoutOverview';
import SearchQrCodeScanner from 'lcm-iot-commons/client/lib/components/QrCodeScanner/SearchQrCodeScanner';
import ProductCreate from '../components/Products/ProductCreate';
import ProductEdit from '../components/Products/ProductEdit';
import ProductDetails from '../components/Products/ProductDetails';

import Administration from '../components/Administration/Administration';
import ConnectSubscriptions from '../components/Subscriptions/ConnectSubscriptions';
import ConnectSubscriptionCreate from '../components/Subscriptions/ConnectSubscriptionCreate';
import ConnectSubscriptionDetails from '../components/Subscriptions/ConnectSubscriptionDetails';
import ConnectSubscriptionEdit from '../components/Subscriptions/ConnectSubscriptionEdit';
import ConnectSubscriptionBillingDetailsEdit from '../components/Subscriptions/ConnectSubscriptionBillingDetailsEdit';
import ConnectSubscriptionCheckout from '../components/Subscriptions/ConnectSubscriptionCheckout';
import ConnectSubscriptionAssignedAssetsEdit from '../components/Subscriptions/ConnectSubscriptionAssignedAssetsEdit';

import ApiKeyCreate from '../components/ApiKeys/ApiKeyCreate';
import EdgeDeviceDetails from '../components/EdgeDevice/EdgeDeviceDetails';
import EdgeDeviceEdit from '../components/EdgeDevice/EdgeDeviceEdit';
import EdgeDeviceAdd from '../components/EdgeDevice/EdgeDeviceAdd';
import EdgeDevices from '../components/EdgeDevice/EdgeDevices';
import FieldGatewayCreateEdit from '../components/EdgeDevice/FieldGatewayCreateEdit';
import FieldGatewayDetails from '../components/EdgeDevice/FieldGatewayDetails';
import UpdateSchedulesDetails from '../components/EdgeDevice/UpdateSchedulesDetails';
import NetworkInterfaceDetails from '../components/EdgeDevice/NetworkInterfaceDetails';
import NetworkInterfaceEdit from '../components/EdgeDevice/NetworkInterfaceEdit';
import OpcUaServerDetails from '../components/OpcUaServer/OpcUaServerDetails';
import PermissionRequest from '../components/PermissionRequest/PermissionRequest';
import PermissionRequests from '../components/PermissionRequest/PermissionRequests';
import RequestPasswordReset from '../components/Users/RequestPasswordReset';
import MultiFactorAuthenticationEdit from '../components/UserProfile/MultiFactorAuthenticationEdit';
import PasswordReset from '../components/Users/PasswordReset';
import ServiceSubscriptions from '../components/Subscriptions/ServiceSubscriptions';
import ResellerSubscriptions from '../components/Subscriptions/ResellerSubscriptions';
import ResellerCustomers from '../components/Subscriptions/ResellerCustomers';
import ResellerCustomerDetails from '../components/Subscriptions/ResellerCustomerDetails';
import ResellerCustomerCreateEdit from '../components/Subscriptions/ResellerCustomerCreateEdit';
import ResellerCustomerEditAccessRights from '../components/Subscriptions/ResellerCustomerEditAccessRights';

import Products from '../components/Products/Products';
import DocumentCreate from '../components/Documents/DocumentCreate';
import AttachmentAdd from '../components/Attachments/AttachmentAdd';
import PlanVariantSelectionUpgrade from '../components/PlanVariants/PlanVariantSelectionUpgrade';
import SignIn from '../components/Users/SignIn';
import SignUp from '../components/Users/SignUp';
import TenantCreate from '../components/Tenants/TenantCreate';
import TenantDetails from '../components/Tenants/TenantDetails';
import TenantEdit from '../components/Tenants/TenantEdit';
import Tenants from '../components/Tenants/Tenants';
import UserGroups from '../components/UserGroups/UserGroups';
import UserProfile from '../components/UserProfile/UserProfile';
import UserProfileEdit from '../components/UserProfile/UserProfileEdit';
import UserRoleDetails from '../components/UserRoles/UserRoleDetails';
import Taxes from '../components/Taxes/Taxes';
import TaxCreate from '../components/Taxes/TaxCreate';
import TaxEdit from '../components/Taxes/TaxEdit';
import OpcUaServerCaCertificateUpload from '../components/OpcUaServer/OpcUaServerCaCertificateUpload';
import AccountConfirmation from '../components/Users/AccountConfirmation';
import SignUpIdentification from '../components/Users/SignUpIdentification';
import SignUpSuccess from '../components/Users/SignUpSuccess';
import SignInTermsConfirmation from '../components/Users/SignInTermsConfirmation';

export function Routes() {
  return (
    <Switch>
      <Route path={url('/redirect/*')} component={RedirectToApp} exact />
      <Route
        path={url('/search_scan')}
        component={
                /* istanbul ignore next */
                // eslint-disable-next-line react/no-unstable-nested-components
                () => <SearchQrCodeScanner source="/nodes" />
            }
        exact
      />
      <Route path={url('')} component={UserProfile} exact />
      <Route path={url('/assets/create')} component={AssetCreate} exact />
      <Route path={url('/assets/:id/edit')} component={AssetEdit} exact />
      <Route path={url('/assets/:id')} component={AssetDetails} exact />
      <Route path={url('/feedback')}>
        <Route path="*" component={Feedback} />
      </Route>
      <Route path={url('/assets/:assetId/instrumentations/create')} component={InstrumentationCreate} exact />
      <Route path={url('/assets/:assetId/instrumentations/assign')} component={InstrumentationAssign} exact />
      <Route path={url('/instrumentations/create')} component={InstrumentationCreate} exact />
      <Route path={url('/instrumentations/:id')} component={InstrumentationDetails} exact />
      <Route path={url('/instrumentations/:id/edit')} component={InstrumentationEdit} exact />
      <Route path={url('/nodes')} component={AllObjects} exact />
      <Route path={url('/nodes/create')} component={NodeCreate} exact />
      <Route path={url('/nodes/:id')} component={AllObjects} exact />
      <Route path={url('/nodes/:id/create')} component={NodeCreate} exact />
      <Route path={url('/nodes/:id/edit')} component={NodeEdit} exact />
      <Route path={url('/nodes/:id/assets/create')} component={AssetCreate} exact />
      <Route path={url('/nodes/:id/instrumentations/create')} component={InstrumentationCreate} exact />
      <Route path={url('/nodes/:nodeId/assets/:assetId/instrumentations/create')} component={InstrumentationCreate} exact />
      <Route path={url('/nodes/:nodeId/assets/:assetId/instrumentations/assign')} component={InstrumentationAssign} exact />
      <Route path={url('/products/create')} component={ProductCreate} exact />
      <Route path={url('/products/:id/edit')} component={ProductEdit} exact />
      <Route path={url('/products/:id')} component={ProductDetails} exact />
      <Route path={url('/edge_devices')} component={EdgeDevices} exact />
      <Route path={url('/edge_devices/add')} component={EdgeDeviceAdd} exact />
      <Route path={url('/edge_devices/:id')} component={EdgeDeviceDetails} exact />
      <Route path={url('/edge_devices/:id/edit')} component={EdgeDeviceEdit} exact />
      <Route path={url('/edge_devices/:id/update_schedules/:updateSchedulesId')} component={UpdateSchedulesDetails} exact />
      <Route path={url('/edge_devices/:id/field_gateways/create')} component={FieldGatewayCreateEdit} exact />
      <Route path={url('/edge_devices/:id/field_gateways/:fieldGatewayId')} component={FieldGatewayDetails} exact />
      <Route path={url('/edge_devices/:id/field_gateways/:fieldGatewayId/edit')} component={FieldGatewayCreateEdit} exact />
      <Route path={url('/edge_devices/:id/network_interfaces/:networkInterfaceId')} component={NetworkInterfaceDetails} exact />
      <Route path={url('/edge_devices/:id/network_interfaces/:networkInterfaceId/edit')} component={NetworkInterfaceEdit} exact />
      <Route path={url('/permission_requests')} component={PermissionRequests} exact />
      <Route path={url('/permission_requests/:id')} component={PermissionRequest} exact />
      <Route path={url('/profile')} component={UserProfile} exact />
      <Route path={url('/profile/edit')} component={UserProfileEdit} exact />
      <Route path={url('/administration')} component={Administration} exact />
      <Route path={url('/multi_factor_authentication')} component={MultiFactorAuthenticationEdit} exact />
      <Route path={url('/subscriptions/connect/:subscription_id/opc_ua_servers/:id/upload_ca_certificate')} component={OpcUaServerCaCertificateUpload} exact />
      <Route path={url('/subscriptions/connect/:subscription_id/opc_ua_servers/:id')} component={OpcUaServerDetails} exact />
      <Route path={url('/password_reset')} component={PasswordReset} exact />
      <Route path={url('/request_password_reset')} component={RequestPasswordReset} exact />
      <Route path={url('/session_expired')} component={SessionExpired} exact />
      <Route path={url('/sign_in')} component={SignIn} exact />
      <Route path={url('/sign_up')} component={SignUp} exact />
      <Route path={url('/sign_up_success')} component={SignUpIdentification} exact />
      <Route path={url('/sign_up_complete')} component={SignUpSuccess} exact />
      <Route path={url('/sign_in_confirmation')} component={SignInTermsConfirmation} exact />
      <Route path={url('/subscriptions/services')} component={ServiceSubscriptions} exact />
      <Route path={url('/reseller/subscriptions')} component={ResellerSubscriptions} exact />
      <Route path={url('/reseller/customers')} component={ResellerCustomers} exact />
      <Route path={url('/reseller/customers/:id/edit')} component={ResellerCustomerEditAccessRights} exact />
      <Route path={url('/reseller/customers/create')} component={ResellerCustomerCreateEdit} exact />
      <Route path={url('/reseller/customers/:id')} component={ResellerCustomerDetails} exact />
      <Route path={url('/subscriptions/connect')} component={ConnectSubscriptions} exact />
      <Route path={url('/subscriptions/connect/:id')} component={ConnectSubscriptionDetails} exact />
      <Route path={url('/subscriptions/connect/new/overview')} component={SubscriptionCheckoutOverview} exact />
      <Route path={url('/subscriptions/connect/:id/edit')} component={ConnectSubscriptionEdit} exact />
      <Route path={url('/subscriptions/connect/:id/billing_details/edit')} component={ConnectSubscriptionBillingDetailsEdit} exact />
      <Route path={url('/subscriptions/connect/:id/edit/:checkout')} component={ConnectSubscriptionBillingDetailsEdit} exact />
      <Route path={url('/subscriptions/connect/:id/api_keys/create')} component={ApiKeyCreate} exact />
      <Route path={url('/subscriptions/connect/:id/assets/assign')} component={ConnectSubscriptionAssignedAssetsEdit} exact />
      <Route path={url('/subscriptions/:id')} component={ConnectSubscriptions} exact />
      <Route path={url('/plan_variants')} component={PlanVariantSelection} exact />
      <Route path={url('/plan_variants/:id')} component={PlanVariantSelection} exact />
      <Route path={url('/subscriptions/connect/:id/upgrade')} component={PlanVariantSelectionUpgrade} exact />
      <Route path={url('/subscription/create')} component={ConnectSubscriptionCreate} exact />
      <Route path={url('/subscription/connect/:id/checkout')} component={ConnectSubscriptionCheckout} exact />
      <Route path={url('/subscription/edit')} component={SubscriptionEdit} exact />
      <Route path={url('/subscription/failed')} component={SubscriptionFailed} exact />
      <Route path={url('/support/tickets')} component={Tickets} exact />
      <Route path={url('/support/tickets/create')} component={TicketCreate} exact />
      <Route path={url('/support/tickets/:id')} component={TicketDetails} exact />
      <Route path={url('/usergroups')} component={UserGroups} exact />
      <Route path={url('/usergroups/create')} component={UserGroupCreate} exact />
      <Route path={url('/usergroups/:id/edit')} component={UserGroupEdit} exact />
      <Route path={url('/usergroups/:id')} component={UserGroupDetails} exact />
      <Route path={url('/userrole')} component={UserRoleDetails} exact />
      <Route path={url('/tenants')} component={Tenants} exact />
      <Route path={url('/tenants/create')} component={TenantCreate} exact />
      <Route path={url('/tenants/:id')} component={TenantDetails} exact />
      <Route path={url('/tenants/:id/edit')} component={TenantEdit} exact />
      <Route path={url('/taxes')} component={Taxes} exact />
      <Route path={url('/taxes/create')} component={TaxCreate} exact />
      <Route path={url('/taxes/:id/edit')} component={TaxEdit} exact />
      <Route path={url('/products')} component={Products} exact />
      <Route path={url('/products/risk_of_maintainability/:riskOfMaintainability')} component={Products} exact />
      <Route path={url('/products/create')} component={Products} exact />
      <Route path={url('/products/:id/edit')} component={Products} exact />
      <Route path={url('/products/:id')} component={Products} exact />
      <Route path={url('/:model/:id/documents/create')} component={DocumentCreate} exact />
      <Route path={url('/:model/:id/documents/:documentId/attachments/add')} component={AttachmentAdd} exact />
      <Route path={url('/confirmation')} component={AccountConfirmation} exact />
      <Route path={url('/403')} component={Forbidden} exact />
      <Route path={url('/404')} component={NotFound} exact />
      <Route path={url('/500')} component={Unknown} exact />
      <Route component={AutoRouting} exact />
    </Switch>
  );
}

export default Routes;
